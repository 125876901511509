import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { sidebarItems } from '../menu-items'
import propTypes from 'prop-types'
import { trackEvent } from '../../../../../utils/analytics'
import useAuth from 'hooks/use-auth'
import useHasSubscription from '../../../../../hooks/useHasSubscription'
import { useSelector } from 'react-redux'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import UserDropdown from '../header/user-dropdown'
import ProvenLogo from '../../../../shared/proven-logo'
import { LOGO_DOUBLE_LINE, LOGO_SEPHORA_DOUBLE_LINE } from '../../../../../constants/logos'
import { ThemeProvider } from '@mui/material/styles'
import { createProvenTheme } from '../../../../../styles/theme-proven'
import useUserTypeAccess from '../../../../../hooks/useUserTypeAccess'

const themeProven = createProvenTheme()

export const MIN_SIDE_BAR_WIDTH_IN_PIXELS_NUMBER = 248
export const MAX_SIDE_BAR_WIDTH_IN_PIXELS_NUMBER = 380

function SideBar(props) {
  const sidebarRef = useRef(null)
  const location = useLocation()
  const { messengerUnreadCount } = useSelector(state => state.support)
  const onSupportPage =
    location.pathname === '/account/support' || location.pathname === '/account/support/'
  const { isAuthenticated } = useAuth()
  const hasSubscription = useHasSubscription()
  const history = useHistory()
  const [displayLogo, setDisplayLogo] = useState(true)
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
  const { hasAccessTo } = useUserTypeAccess()

  const handleClick = text => {
    const dashboard = isAuthenticated && hasSubscription ? 'RUD' : undefined
    trackEvent('menu_cta', {
      CTA: text,
      dashboard: dashboard,
      location: 'left_desktop_menu'
    })
  }
  const handleLogoClick = () => {
    handleClick('PROVEN Logo')
    history.push('/')
  }

  const onScroll = () => {
    const rect = sidebarRef.current?.getBoundingClientRect()
    if (rect) {
      const { height, top } = rect
      const visiblePartOfSidebar = height + top
      if (visiblePartOfSidebar < window.innerHeight - 1) {
        setDisplayLogo(false)
      } else {
        setDisplayLogo(true)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const fullHeigth = { height: '100vh' }
  const autoHeigth = { height: 'auto' }

  const containerSx = {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '26px 0 34px 0'
  }

  const itemSx = {
    margin: '0 8px 16px 8px',
    pl: 2,
    py: 2,
    borderRadius: '8px',
    '& svg': {
      color: themeProven.palette.gray.middleGray
    },
    '&:hover': {
      '& a span': {
        color: themeProven.palette.gray.white
      },
      '& svg': {
        color: themeProven.palette.gray.white
      }
    },
    '&:last-child': {
      mb: 0
    }
  }

  /**
   * TODO CHECK THIS is ok
   */
  const sideBarSx = {
    zIndex: 6,
    background: '#303C42',
    color: '#ffffff',
    width: {
      xs: `${MIN_SIDE_BAR_WIDTH_IN_PIXELS_NUMBER}px`,
      xxl: `${MAX_SIDE_BAR_WIDTH_IN_PIXELS_NUMBER}px`
    }
  }

  return (
    <ThemeProvider theme={themeProven}>
      <Box
        ref={sidebarRef}
        sx={{
          zIndex: 999,
          background: themeProven.palette.gray.elysian,
          color: themeProven.palette.gray.white,
          width: '200px'
        }}
      >
        <Box sx={Object.assign({}, containerSx, displayLogo ? fullHeigth : autoHeigth)}>
          <Box sx={{ px: 3, width: '100%' }}>
            <UserDropdown dashboard="RUD" />
          </Box>
          <Box component="nav" sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {props.items.map(
              (item, i) =>
                ((props.userWillBeASubscriber && item.id !== 'skincare' && item.id !== 'profile') ||
                  (props.userWillBeASystemSubscriber &&
                    (item.id === 'skincare' || item.id === 'profile')) ||
                  hasAccessTo(item.href)) && (
                  <React.Fragment key={`sidebar-item${i}`}>
                    <Stack
                      sx={Object.assign(
                        {},
                        itemSx,
                        props.selected === item.id
                          ? {
                              backgroundColor: themeProven.palette.primary.main,
                              '& svg': {
                                color: themeProven.palette.gray.white
                              }
                            }
                          : {}
                      )}
                    >
                      <Link to={item.href} onClick={() => handleClick(item.text)}>
                        <Stack
                          flexDirection="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          gap={2}
                        >
                          {item.icon}
                          {!!messengerUnreadCount && item.id === 'support' && (
                            <Box
                              sx={{
                                position: 'absolute',
                                width: '7px',
                                height: '7px',
                                background: themeProven.palette.primary.main,
                                borderRadius: '50%',
                                border: '1px solid #323232',
                                top: 0,
                                left: '16px'
                              }}
                            />
                          )}
                          <Typography
                            variant="footnote"
                            color={props.selected === item.id ? 'gray.white' : 'gray.middleGray'}
                          >
                            {item.text}
                          </Typography>
                        </Stack>
                      </Link>
                    </Stack>
                  </React.Fragment>
                )
            )}
          </Box>

          {displayLogo && (
            <Box sx={{ alignSelf: 'center' }}>
              <ProvenLogo variant={LOGO_DOUBLE_LINE} inWhite onClick={handleLogoClick} />
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  )

  /*return (
    <ThemeProvider theme={themeProven}>
      <Box sx={sideBarSx} ref={sidebarRef}>
        <div styleName={containerStyles}>
          <div styleName="user-drop-down">
            <UserDropdown dashboard="RUD" />
          </div>
          <nav styleName="nav">
            {props.items.map(
              (item, i) =>
                hasAccessTo(item.href) && (
                  <React.Fragment key={`sidebar-item${i}`}>
                    <div styleName={`item  ${props.selected === item.id ? 'selected' : ''}`}>
                      <Link to={item.href} onClick={() => handleClick(item.text)}>
                        {item.icon}
                        {!!messengerUnreadCount && item.id === 'support' && (
                          <div styleName="red-dot" />
                        )}
                        <span>{item.text}</span>
                      </Link>
                    </div>
                  </React.Fragment>
                )
            )}
          </nav>

          <div styleName={logoStyles}>
            <ProvenLogo
              variant={
                appEdition === APP_EDITION_SEPHORA ? LOGO_SEPHORA_DOUBLE_LINE : LOGO_DOUBLE_LINE
              }
              inWhite
              onClick={handleLogoClick}
            />
          </div>
        </div>
      </Box>
    </ThemeProvider>
  )*/
}

SideBar.defaultProps = {
  selected: sidebarItems[0]['id'],
  items: sidebarItems
}

SideBar.propTypes = {
  selected: propTypes.string,
  items: propTypes.array
}

export default SideBar
